@import 'styles/theme.scss';

.features {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      margin-bottom: 0.8rem;
    }

    svg {
      color: adjust-color($color: $color-text, $alpha: -0.3);
    }

    :global .text.body {
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: 0.6rem;
      margin-bottom: -1px;
      color: adjust-color($color: $color-text, $alpha: -0.4);

      b {
        margin-right: 0.5ch;
        font-weight: normal;
        color: $color-text;
      }

      svg {
        margin-left: 0.5ch;
      }
    }
  }
}
