@import 'styles/theme.scss';

.button {
  composes: base button from '../Text/Text.module.scss';

  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  height: 3rem;
  border-radius: $border-radius;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    color: adjust-color($color: $color-text, $alpha: -0.5);
    cursor: not-allowed;
  }

  svg {
    margin-left: 1ch;
  }

  &:global(.primary) {
    background-color: $color-primary;

    &:disabled {
      background-color: $color-border;
    }
  }

  &:global(.filled) {
    background-color: $color-border;

    &:disabled {
      background-color: $color-border;
    }
  }

  @include tablet {
    padding: 0 1.6rem;
    height: 2.4rem;
  }
}

.loading {
  > svg:not(:local(.spinner)) {
    display: none;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0turn);
  }
  100% {
    transform: rotate(1turn);
  }
}

.spinner {
  animation: spin 2.4s infinite linear;
}
