@import 'styles/theme.scss';

.checkbox {
  composes: base label from '../Text/Text.module.scss';

  display: flex;
  position: relative;
  flex-direction: row-reverse;
  justify-content: flex-end;
  user-select: none;

  input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 28px;
    height: 28px;
    visibility: hidden;
  }

  svg {
    flex-shrink: 0;
    margin-top: -1px;
    margin-right: 0.8rem;
  }
}

.checked {
  svg {
    color: $color-primary;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
