@import 'styles/theme.scss';

.overview {
  composes: question from '../Question/Question.module.scss';
}

.content {
  composes: content from '../Question/Question.module.scss';

  h1 > span {
    display: inline-block;
    margin-left: -0.2rem;
    transform-origin: center bottom;
    transform: rotate(15deg);
  }

  ul {
    margin-top: 1.6rem;

    a {
      color: $color-primary;
    }
  }

  :global(.error) {
    margin-top: 3rem;
  }
}

.app {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  max-height: 0px;
  visibility: hidden;
  opacity: 0;

  transition: height 1s ease, max-height 1s ease, opacity 1s ease, margin-top 1s ease;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  > div:first-of-type {
    margin-bottom: 0.2rem;
    margin-left: -0.4rem;

    img {
      margin-right: 0.5rem;
      padding: 2px;
      border-radius: 6px;
      box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.2), 0 4px 8px 3px rgba(60, 64, 67, 0.1);
    }

    p {
      margin: 0;
    }
  }

  > p {
    flex-shrink: 0;
    margin: 0;
    margin-top: 0.1rem;
    width: 100%;
    max-width: none;
    font-size: 0.5rem;
    text-align: center;
    opacity: 0.5;
  }

  @include tablet {
    > div {
      justify-content: flex-start;
    }

    > div:first-of-type {
      margin-left: 0;
    }

    > p {
      text-align: left;
    }
  }
}

.appVisible {
  margin-top: 2.6rem;
  max-height: 180px;
  visibility: visible;
  opacity: 1;
}

.error-details {
  font-size: 50%;
  color: #ddd;
}
