@import 'styles/theme.scss';

.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
  z-index: 1000;
}

.dialog {
  padding: 32px;
  width: min(100%, 500px);
  background-color: $color-foreground;
  border-radius: $border-radius;
  z-index: 1000;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
}