@import 'styles/theme.scss';

@mixin label-hover {
  svg {
    &:global(.filled) {
      display: initial;
      color: $color-action-hover;
    }

    &:global(.empty) {
      display: initial;
      position: absolute;
      color: $color-action-hover;
    }
  }
}

@mixin label-checked {
  svg {
    &:global(.filled) {
      display: initial;
      color: $color-primary;
    }

    &:global(.empty) {
      display: none;
    }
  }
}

@mixin label-focus {
  outline-style: solid;
  outline-width: 4px;
  outline-offset: 8px;
  outline-color: $color-primary;
}

.rating {
  display: flex;
  margin: 0 auto;

  /* Some CSS magic to fill in each star after the selected one */
  input:nth-of-type(1):checked ~ label:nth-of-type(1),
  input:nth-of-type(2):checked ~ label:nth-of-type(-n + 2),
  input:nth-of-type(3):checked ~ label:nth-of-type(-n + 3),
  input:nth-of-type(4):checked ~ label:nth-of-type(-n + 4),
  input:nth-of-type(5):checked ~ label:nth-of-type(-n + 5) {
    @include label-checked;
  }

  /* Some CSS magic to highlight each star after the hovered one */
  input:nth-of-type(1):hover ~ label:nth-of-type(1),
  input:nth-of-type(2):hover ~ label:nth-of-type(-n + 2),
  input:nth-of-type(3):hover ~ label:nth-of-type(-n + 3),
  input:nth-of-type(4):hover ~ label:nth-of-type(-n + 4),
  input:nth-of-type(5):hover ~ label:nth-of-type(-n + 5) {
    @include label-hover;
  }

  /* Some CSS magic to apply focus styles only to the focused star */
  input:nth-of-type(1):focus-visible ~ label:nth-of-type(1),
  input:nth-of-type(2):focus-visible ~ label:nth-of-type(2),
  input:nth-of-type(3):focus-visible ~ label:nth-of-type(3),
  input:nth-of-type(4):focus-visible ~ label:nth-of-type(4),
  input:nth-of-type(5):focus-visible ~ label:nth-of-type(5) {
    @include label-focus;
  }

  input {
    position: absolute;

    /* Hide input while still retaining tab & focus functionality */
    clip: rect(1px, 1px, 1px, 1px);
  }

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.5rem;
    user-select: none;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    svg {
      color: adjust-color($color-border, $lightness: -10%);

      &:global(.filled) {
        display: none;
      }

      &:global(.empty) {
        display: initial;
      }
    }

    span {
      margin-top: 0.2rem;
      color: adjust-color($color: $color-text, $alpha: -0.6);
    }
  }

  @include tablet {
    margin: 0;
  }
}

.disabled {
  opacity: 0.5;

  /* Some CSS magic to highlight each star after the hovered one */
  input:nth-of-type(1):hover ~ label:nth-of-type(1),
  input:nth-of-type(2):hover ~ label:nth-of-type(-n + 2),
  input:nth-of-type(3):hover ~ label:nth-of-type(-n + 3),
  input:nth-of-type(4):hover ~ label:nth-of-type(-n + 4),
  input:nth-of-type(5):hover ~ label:nth-of-type(-n + 5) {
    svg {
      &:global(.filled) {
        color: transparent;
      }

      &:global(.empty) {
        color: adjust-color($color-border, $lightness: -10%);
      }
    }
  }

  label {
    cursor: not-allowed;
  }
}
