@import 'styles/theme.scss';

.count {
  width: 100%;

  @include tablet {
    // width: 70%;
  }
}

.disabled {
  :local(.range) > label {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.input {
  display: flex;
  justify-content: center;
  width: 100%;

  :global(.input) {
    margin-bottom: 0.8rem;
    padding: 0.3rem 0.5rem 0 0.5rem;
    border: 2px solid $color-border;
  }
}

.range {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &:first-of-type {
    justify-content: center;
  }

  > span {
    width: 20px;
    text-align: center;
    transform-origin: bottom center;
    background-color: $color-beige;
    user-select: none;
    cursor: pointer;

    transition: transform 150ms cubic-bezier(0.25, 1, 0.5, 1);

    &:global(.selected) {
      transform: scale(1.5);
    }
  }

  > label {
    $border-radius: 2rem;

    padding: 0.2rem 0.55rem;
    border-radius: $border-radius;
    background-color: $color-border;
    user-select: none;
    cursor: pointer;

    &:first-of-type {
      border-radius: 0 $border-radius $border-radius $border-radius;
    }

    &:last-of-type {
      border-radius: $border-radius 0 $border-radius $border-radius;
    }
  }
}
