@use 'sass:math';
@import 'styles/theme.scss';

$spacing: 2.4rem;

.intro {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  width: 100%;
  background-color: $color-background;
  box-sizing: border-box;

  img {
    margin-bottom: 1rem;
    opacity: 1;

    transform-origin: left center;
    transform: scale(0.7);

    @include desktop {
      transform: scale(1);
    }
  }

  > svg {
    position: absolute;
    max-height: 50%;
    width: 100%;
    z-index: 0;

    &:first-of-type {
      top: 0;
      color: adjust-color($color: $color-background, $lightness: +3%);
    }

    &:last-of-type {
      bottom: 0;
      color: adjust-color($color: $color-background, $lightness: -3%);
    }
  }

  :global .text.hero {
    position: relative;
    margin: 0;
    margin-bottom: $spacing;
    overflow-wrap: break-word;
    color: $color-foreground;

    // Keep newlines
    white-space: pre-wrap;

    &::after {
      content: '';
      position: absolute;
      bottom: math.div(-$spacing, 2);
      left: 0;
      height: 2px;
      width: 6rem;
      background-color: $color-primary;
    }
  }

  :global .text.subheader {
    margin-bottom: $spacing;
    overflow-wrap: break-word;
    color: adjust-color($color: $color-foreground, $alpha: -0.2);

    // Keep newlines
    white-space: pre-wrap;
  }

  > div {
    @include content;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    z-index: 1;

    > div {
      width: 100%;
      max-width: 65ch;
    }
  }

  ul li {
    svg {
      color: adjust-color($color: $color-foreground, $alpha: -0.2);
    }

    :global .text.body {
      color: adjust-color($color: $color-foreground, $alpha: -0.5);

      b {
        color: adjust-color($color: $color-foreground, $alpha: -0.2);
      }
    }
  }

  nav {
    margin-bottom: 0;
    z-index: 1;

    @include tablet {
      margin-bottom: 2rem;
    }
  }

  nav :global(.secondary) {
    color: adjust-color($color: $color-foreground, $alpha: -0.5);
  }

  @include desktop {
    margin-top: -1rem;
    padding-top: 0;

    > div > div {
      width: 100%;
    }

    nav {
      display: none;
    }
  }
}
