@import 'styles/theme.scss';

.input {
  composes: base input from '../Text/Text.module.scss';

  display: flex;
  position: relative;
  border-bottom: 2px solid $color-border;

  input,
  select {
    padding: 0;
    padding-bottom: 0.3rem;
    width: 100%;
    border-radius: 0;
    border: none;
    outline: none;

    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;

  input {
    cursor: not-allowed;
  }
}

.label {
  composes: base label from '../Text/Text.module.scss';
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  font-size: 0.8rem;
  user-select: none;
  pointer-events: none;
}

.prefix {
  flex-shrink: 0;
  user-select: none;
}
