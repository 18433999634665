@import 'styles/theme.scss';

.nps {
  position: relative;
  padding-top: 1rem;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > span {
      position: relative;
      width: 20px;
      text-align: center;
      color: adjust-color($color: $color-text, $alpha: -0.6);
      user-select: none;
      cursor: pointer;

      > span {
        display: block;
        transform-origin: bottom center;
        transition: transform 150ms cubic-bezier(0.25, 1, 0.5, 1);
      }

      &:global(.selected) {
        > span {
          transform: scale(1.5);
        }
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 32px;
        width: 2px;
        height: 18px;
        background-color: $color-border;
        z-index: -1;

        transform: translateX(-50%);
      }
    }
  }

  > label {
    display: flex;
    align-items: center;
    color: adjust-color($color: $color-text, $alpha: -0.4);
    user-select: none;

    :global(.spacer) {
      flex: 1;
    }
  }
}

.disabled {
  > div > span {
    opacity: 0.5;
    cursor: not-allowed;
  }

  > label {
    opacity: 0.5;
  }
}

.label {
  position: absolute;
  top: -32px;
  min-width: 42px;
  height: 42px;
  user-select: none;
  pointer-events: none;

  > span {
    display: block;
    position: absolute;
    padding: 0 0.5rem;
    left: 50%;
    width: auto;
    min-width: 42px;
    height: 42px;
    text-align: center;
    line-height: 44px;
    border-radius: 32px;
    background-color: $color-border;
    z-index: 2;

    transform: translateX(-50%);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 42px;
    height: 100%;
    border-radius: 32px 32px 32px 0;
    background-color: $color-border;
    z-index: 1;

    transform: translateX(-50%) rotate(-45deg);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 42px;
    height: 100%;
    background-color: $color-foreground;

    transform: translateX(-50%);
  }
}
