@import 'styles/theme.scss';

.navigation {
  @include content;

  display: flex;
  flex-direction: column;
  margin-top: 4rem;

  button {
    justify-content: center;
    max-width: none;

    &:global(.secondary) {
      font-size: 0.64rem;
    }

    span:global(.tablet) {
      display: none;
    }

    &:not(:global(.loading)) svg {
      display: none;
    }

    @include tablet {
      justify-content: flex-start;

      &:first-of-type {
        margin-left: 0.5rem;
      }

      &:global(.secondary) {
        font-size: 1rem;
      }

      span:global(.tablet) {
        display: initial;
      }

      span:global(.mobile) {
        display: none;
      }

      &:not(:global(.loading)) svg {
        display: initial;
      }
    }
  }

  @include tablet {
    flex-direction: row-reverse;
  }
}

.previous {
  svg {
    margin-left: 0;
    margin-right: 0.3rem;
  }
}

.noPrevious {
  padding-bottom: 1.2rem;

  @include tablet {
    padding-bottom: 0;
  }
}
