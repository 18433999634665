$color-primary: #f98c3f;
$color-foreground: #ffffff;
$color-background: #222222;
$color-beige: #f7f6f1;
$color-border: #e8e6db;
$color-text: #000000;
$color-action: $color-primary;
$color-action-hover: adjust-color($color-primary, $lightness: +8%);
$color-red: #d05353;

$font-header: 'Work Sans', sans-serif;
$font-body: 'Atkinson Hyperlegible', sans-serif;

$border-radius: 0.2rem;

$tablet-width: 768px;
$desktop-width: 1024px;
$side-padding: 1.2rem;

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin content {
  position: relative;
  margin: 0 auto;
  padding: 0 $side-padding;
  width: 100vw;
  max-width: min(60ch, 100%);

  box-sizing: border-box;
}
