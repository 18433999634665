@import 'styles/theme.scss';

.help {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;

  svg {
    flex-shrink: 0;
    margin-right: 0.2rem;
  }
}
