@import 'styles/theme.scss';

.slider {
  margin: 0;
  height: 32px;
  width: 100%;

  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  background: transparent; /* Otherwise white in Chrome */

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline-style: solid;
    outline-width: 4px;
    outline-offset: 8px;
    outline-color: $color-primary;
  }

  &:hover {
    &::range-thumb {
      background-color: $color-action-hover;
    }
  }

  &:active {
    cursor: grabbing;

    &::range-track {
      cursor: grabbing;
    }

    &::range-thumb {
      background-color: $color-primary;
      cursor: grabbing;
    }
  }

  &::range-track {
    width: 100%;
    height: 8px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='58' height='6' viewBox='0 0 58 6' preserveAspectRatio='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M58 0L0 2V4L58 6V0Z' fill='#{rgba($color-border, 0.9999)}'/%3E%3C/svg%3E%0A");
    cursor: initial;
  }

  &::range-thumb {
    /* Hides the slider so custom styles can be added */
    -webkit-appearance: none;
    background: transparent;
    border-color: transparent;
    color: transparent;

    margin-top: -14px;
    height: 36px;
    width: 18px;
    border-radius: 2px;
    border: none;
    outline: 4px solid $color-foreground;
    cursor: grab;
    z-index: 10;

    /* Colored background with the "grab" icon embedded in */
    background-size: 6px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='3' height='8' opacity='0.7' viewBox='0 0 3 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H1V8H0V0Z' fill='black'/%3E%3Cpath d='M2 0H3V8H2V0Z' fill='black'/%3E%3C/svg%3E%0A");
    background-color: $color-action;
  }
}

.disabled {
  opacity: 0.5;

  &:active {
    cursor: not-allowed;

    &::range-track {
      cursor: not-allowed;
    }

    &::range-thumb {
      background-color: $color-border;
      cursor: not-allowed;
    }
  }

  &:hover {
    &::range-thumb {
      background-color: $color-border;
    }
  }

  &::range-track {
    cursor: not-allowed;
  }

  &::range-thumb {
    background-color: $color-border;
    cursor: not-allowed;
  }
}
