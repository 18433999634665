@import 'styles/theme.scss';

.study {
  display: flex;
  min-height: 100%;

  --swiper-theme-color: #{$color-primary};
}

.studyIntro {
  :local(.content) {
    display: none;
  }

  @include desktop {
    :local(.content) {
      display: flex;
    }
  }
}

.side {
  display: flex;
  width: 100%;
  min-height: 100%;
  overflow: hidden;

  > article > svg {
    opacity: 1;

    transition: opacity 1200ms 400ms ease;
  }

  @include desktop {
    width: 55%;

    transition: margin 900ms cubic-bezier(0.85, 0, 0.15, 1);
  }
}

.sideHidden {
  display: none;

  > article > svg {
    opacity: 0;

    transition: opacity 700ms 0ms ease;
  }

  @include desktop {
    display: flex;
    margin-left: -55%;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  position: relative;
  min-height: 100%;
  padding-bottom: 0;
  overflow: hidden;

  > svg {
    position: absolute;
    max-height: 50%;
    width: 100%;
    z-index: -1;

    &:first-of-type {
      top: -25%;
      color: adjust-color($color: #f7f6f1, $lightness: +2%);
    }

    &:last-of-type {
      bottom: -20%;
      color: #f7f6f1;
    }
  }

  @include tablet {
    padding-bottom: 6rem;
  }
}

@keyframes entry {
  0% {
    transform: translateX(50%);
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes exit {
  0% {
    visibility: visible;
    transform: translateX(0%);
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  100% {
    transform: translateX(-20%);
    opacity: 0;
  }
}

.question {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-shrink: 0;
  width: 100%;
  overflow: hidden;

  > article {
    animation: entry 750ms cubic-bezier(0.25, 1, 0.5, 1);
  }
}

.exit {
  position: absolute;
  visibility: hidden;
  top: 3rem;
  left: 0;

  > article {
    animation: exit 750ms cubic-bezier(0.25, 1, 0.5, 1);
  }
}

.instruction {
  > article {
    padding-top: 0.5rem;
  }

  @include desktop {
    > article {
      padding-top: 3rem;
    }
  }
}
