@import 'styles/theme.scss';

.answers {
  composes: answers from '../Question/Question.module.scss';

  :global(.input) {
    margin-bottom: 1rem;
    width: 100%;
    border: 2px solid $color-border;
    border-radius: $border-radius;

    &:last-of-type {
      margin-bottom: 2rem;
    }

    input,
    select {
      padding: 1.7rem 0.5rem 0.7rem 0.5rem;
    }

    select {
      padding: 1.7rem 1rem 0.7rem 0.3rem;
      min-height: 73px;
      background-color: transparent;

      // Safari fix
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          text-indent: 5px;
          -webkit-appearance: none;
        }
      }

      // iOS padding fix
      @supports (-webkit-touch-callout: none) {
        text-indent: 6px;
      }
    }
  }

  :global(.error) {
    margin-top: 2rem;
    width: 100%;
  }
}
