@import 'styles/theme.scss';

.question {
  padding-top: 3rem;
  width: 100%;
}

.content {
  @include content;

  :global(.comment) {
    margin-top: 2rem;
  }
}

.number {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  // margin-top: -0.25rem;
  // margin-left: calc(-36px - 0.6rem);
  margin-left: -3px;
  width: 46px;
  height: 46px;

  :global(.text) {
    font-weight: bold;
    font-size: 0.9rem;
    color: adjust-color($color: $color-text, $alpha: -0.4);
  }

  svg {
    position: absolute;
    color: $color-border;
    transform: scaleX(-1);
  }
}

.title {
  margin-top: 0.6rem;
  overflow-wrap: break-word;

  // Keep newlines
  white-space: pre-wrap;
}

.answers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
}
