@import 'styles/theme.scss';

.base {
  max-width: 60ch;
  font-family: $font-body;
  font-weight: normal;
  font-size: 1rem;
  color: adjust-color($color: $color-text, $alpha: -0.2);
}

.hero {
  font-family: $font-header;
  font-weight: 700;
  font-size: max(1.4rem, min(2.2rem, 4vw));
  color: $color-text;
}

.subheader {
  font-size: 1.1rem;
}

.body {
}

.label {
}

.button {
  font-family: $font-header;
  font-weight: 500;
  font-size: 1rem;
}

.fineprint {
  font-size: 0.64rem;
  color: adjust-color($color: $color-text, $alpha: -0.4);
}

.flip {
  position: relative;
}

.flipContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  > * {
    position: absolute;
    top: 0;
    margin: 0;
  }

  > :last-child:not(:first-child) {
    opacity: 0;
  }
}

@keyframes flip-current {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes flip-previous {
  0% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(100%);
  }
}

.flipAnimate {
  > :first-child {
    opacity: 0;
  }

  :local(.flipContainer) {
    > :first-child {
      animation: flip-current 1s cubic-bezier(0.25, 1, 0.5, 1);
    }

    > :last-child:not(:first-child) {
      animation: flip-previous 1s cubic-bezier(0.25, 1, 0.5, 1);
    }
  }
}
