@import 'styles/theme.scss';

.container {
  position: relative;
  width: 100%;

  > div {
    display: inline-block;
    position: relative;
    width: 100%;

    @include tablet {
      width: 80%;
    }
  }

  > p {
    position: absolute;
    bottom: -0.55rem;
    z-index: 1;
  }
}

.disabled {
  :local(.textarea) {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.textarea {
  composes: base input from '../Text/Text.module.scss';

  padding: 1rem;
  width: 100%;
  border: 2px solid $color-border;
  outline: none;
  resize: none;

  &:focus {
    border-color: $color-primary;
  }
}

.requirements {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  z-index: 2;
}

.requirement {
  padding: 0.1rem 0.2rem;
  background-color: $color-beige;
  border-radius: $border-radius;
  user-select: none;
}

.requirementsError {
  background-color: $color-red;
  color: $color-foreground;
  z-index: 2;
}

.requirementsMin {
  justify-self: flex-start;
}
