@import 'styles/theme.scss';

.banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: $color-beige;
  border-radius: $border-radius;

  svg {
    flex-shrink: 0;
    color: inherit;
    opacity: 0.7;
  }

  a {
    text-decoration: underline;
    user-select: none;
    cursor: pointer;
  }
}

.content {
  display: flex;
  align-items: center;
  flex: 1;

  :global(.text) {
    flex: 1;
    margin: 0;
    margin-left: 0.9rem;
    color: inherit !important;
    opacity: 1;
  }
}

.error {
  background-color: $color-red;
  color: $color-foreground;

  animation-name: error;
  animation-duration: 500ms;
  animation-iteration-count: 2;
}

@keyframes error {
  0% {
    background-color: $color-red;
  }
  50% {
    background-color: adjust-color($color: $color-red, $alpha: -0.4);
  }
  100% {
    background-color: $color-red;
  }
}
