@import 'styles/theme.scss';

.container {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3rem;
  z-index: 100;
}

.header {
  @include content;

  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transform: translateY(0%);
  opacity: 1;

  transition: transform 500ms cubic-bezier(0.25, 1, 0.5, 1), opacity 500ms cubic-bezier(0.25, 1, 0.5, 1);

  &::after,
  &::before {
    content: '';
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    // background-color: $color-foreground;
    z-index: -1;
  }

  &::after {
    width: calc(100% - #{$side-padding * 2});
    left: $side-padding;
    z-index: -2;

    transition: box-shadow 100ms ease;
  }

  label {
    flex: 1;
    margin: 0;
    color: $color-foreground;
  }

  svg {
    color: $color-primary;
  }

  @include tablet {
    flex-direction: row;
    // background-color: $color-foreground;

    &::after,
    &::before {
      display: block;
    }

    label {
      color: adjust-color($color: $color-text, $alpha: -0.4);
    }
  }
}

.hidden {
  transform: translateY(-100%);
  opacity: 0;
}

.logo {
  flex: 1;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
  height: 16px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    opacity: 0.3;
  }

  @include tablet {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 1.4rem;
    height: 24px;

    img {
      object-fit: contain;
      object-position: left center;
      opacity: 0.3;
    }
  }
}

.status {
  // margin-right: 1rem;
  text-align: right;

  p {
    margin: 0;
    text-align: center;
  }

  @include tablet {
    p {
      text-align: right;
    }
  }
}

.progress {
  position: relative;
  margin-bottom: 0.3rem;
  width: 16rem;
  height: 0.4rem;
  border-radius: 0.5rem;
  background-color: $color-border;
  overflow: hidden;

  > div {
    content: '';
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: $color-primary;

    transition: width 500ms ease;
  }
}
