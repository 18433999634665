@import 'styles/theme.scss';

.rank {
  position: relative;
  margin-top: -0.5rem;
  width: 100%;
}

.list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;

  transition: padding 200ms ease;

  &:global(.selected) {
    padding-left: 2rem;
  }

  @include desktop {
    &:global(.selected) {
      padding-left: 0;
    }
  }
}

.item {
  position: relative;
  margin-top: 0.5rem;
  width: 100%;
  background-color: $color-foreground;
  border-radius: 4px;
  z-index: 1;

  &:global(.selected) {
    z-index: 2;
  }
}

.number {
  composes: base label from '../../Text/Text.module.scss';

  position: absolute;
  top: 0;
  left: calc(-28px - 0.5rem);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 69px;
  font-weight: bold;
  font-size: 0.8rem;
  color: $color-text;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    // background-color: $color-primary;
    border: 2px solid $color-border;
    border-radius: 50%;
    color: adjust-color($color: $color-text, $alpha: -0.4);
  }

  &:global(.selected) > span {
    background-color: $color-border;
    // border-color: adjust-color($color: $color-primary, $lightness: -30%);
    // color: $color-text;
  }
}

.button {
  composes: answer from '../Option/Option.module.scss';

  &:global(.checkbox) {
    display: flex;
    width: 100%;
    text-align: left;
    cursor: pointer;

    > label {
      padding-right: 1rem;
      cursor: pointer;
    }
  }

  :local(.number) {
    display: none;
  }

  &:global(.checkbox.checked) {
    cursor: grab;

    > label {
      cursor: grab;
    }

    :local(.number) {
      display: flex;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 1rem;
      width: 22px;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3Csvg width='3' height='8' opacity='0.7' viewBox='0 0 3 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0H1V8H0V0Z' fill='black'/%3E%3Cpath d='M2 0H3V8H2V0Z' fill='black'/%3E%3C/svg%3E%0A");
      background-size: 9px;

      transform: rotate(90deg);
    }
  }

  svg {
    flex-shrink: 0;
    margin-top: -1px;
    margin-right: 0.8rem;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;

  * {
    cursor: not-allowed !important;
  }
}
