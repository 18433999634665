@import 'styles/theme.scss';

.container {
  margin-bottom: 1rem;
  margin-left: -$side-padding;
  margin-right: -$side-padding;

  > div,
  > a {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  @include tablet {
    margin-bottom: 1.2rem;
    margin-left: 0;
    margin-right: 0;
  }
}

.media {
  position: relative;
  margin-top: 1.7rem;
  height: calc((60ch + 3rem) * 0.5625);
}

.content {
  width: 100%;
  height: 100%;
  background-color: $color-background;
  border-radius: 0;
  overflow: hidden;

  :global(.swiper) {
    width: 100%;
    height: 100%;
    user-select: none;

    :global(.swiper-pagination-bullets) {
      bottom: 0.1rem;
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: $color-primary;
    }
  }

  @include tablet {
    border-radius: $border-radius;
  }
}

.image {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.caption {
  composes: base fineprint from '../../Text/Text.module.scss';

  position: absolute;
  left: 1rem;
  bottom: 1rem;
  color: $color-foreground;

  span {
    padding: 0.15rem 0.25rem 0.1rem 0.25rem;
    box-decoration-break: clone;
    background-color: $color-background;
  }
}

.controls {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;

  button {
    position: absolute;
    justify-content: center;
    padding: 0;
    top: 50%;
    width: 2.4rem;
    border-radius: 2.4rem;
    background-color: $color-foreground;
    pointer-events: initial;

    &:disabled {
      display: none;
    }

    &:global(.close) {
      top: 0;
      right: 0;
      transform: translate(-50%, 50%);
    }

    &:global(.left) {
      left: 0;
      transform: translate(50%, -50%);

      @include tablet {
        transform: translate(-50%, -50%);
      }
    }

    &:global(.right) {
      right: 0;
      transform: translate(-50%, -50%);

      @include tablet {
        transform: translate(50%, -50%);
      }
    }

    svg {
      margin: 0;
    }
  }
}

.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;

  :local(.media) {
    margin: 0;
    height: 100%;
  }

  :local(.content) {
    background-color: adjust-color($color: $color-background, $alpha: -0.05);
    border-radius: 0;
  }

  :local(.controls) {
    button {
      &:global(.left) {
        transform: translate(50%, -50%);
      }

      &:global(.right) {
        transform: translate(-50%, -50%);
      }
    }
  }
}

.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  padding-left: 1.1rem;
  width: 100%;
  background-color: $color-beige;
  border-radius: $border-radius;
  user-select: none;

  &:hover {
    background-color: $color-border;
  }

  p {
    margin: 0;
  }

  svg {
    margin-right: 0.5rem;
  }
}

.youtube {
  background-color: transparent;

  > div {
    display: flex;
    width: 100%;
    overflow: hidden;

    > iframe {
      width: 100%;
    }

    @include tablet {
      border-radius: $border-radius;
    }
  }
}

.requirements {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
  width: 100%;
  z-index: 2;

  p {
    margin: 0;
    margin-top: 0.1rem;
  }
}

.requirement {
  flex-shrink: 0;
  padding: 0.1rem 0.2rem;
  background-color: $color-beige;
  border-radius: $border-radius;
  user-select: none;
}
