@import 'styles/theme.scss';

.answer {
  user-select: none;

  &:global(.checkbox) {
    position: relative;
    // display: flex;
    // align-items: center;
    width: 100%;
    padding: 1rem 1.2rem 0.9rem 0.8rem;
    // border-radius: 0 $border-radius $border-radius 0;
    border-radius: $border-radius !important;
    border: 2px solid $color-border;
    cursor: pointer;
    z-index: 1;

    &:first-of-type {
      border-radius: $border-radius $border-radius $border-radius 0;
    }

    &:not(:first-of-type) {
      margin-top: 0.5rem;
    }

    &:last-of-type {
      border-radius: 0 $border-radius $border-radius $border-radius;
    }

    &:global(.checked) {
      background-color: $color-primary;
      border-color: adjust-color($color: $color-primary, $lightness: -30%);
      z-index: 2;

      svg {
        color: adjust-color($color: $color-primary, $lightness: -45%);
      }
    }

    @include tablet {
      &:not(button) {
        width: auto;
      }
    }
  }
}
